import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { SidePageService } from '@common/components/sidepage/sidepage.service';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { SettingsComponent } from '../../settings.component';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';
import { FormControl } from '@angular/forms';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { getCurrentUser } from '@store/actions/current_user.actions';

@Component({
  selector: 'app-settings-tooltip',
  templateUrl: './settings-tooltip.component.html',
  styleUrls: ['./settings-tooltip.component.less'],
  providers: [TuiDestroyService],
})
export class SettingsTooltipComponent implements OnInit {
  @Input() isDarkTheme = false;
  @Input() isVisibleAdditionalParams = false;

  currentUser$: Observable<any> = this.store.select(selectCurrentUser);

  nightThemeControl: FormControl = new FormControl(!!window.localStorage.getItem('theme') || false);

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    @Inject(SidePageService) private readonly sidepageService: SidePageService,
    @Inject(Injector) private readonly injector: Injector,
    private destroy$: TuiDestroyService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getCurrentUser());

    if (this.isDarkTheme) {
      this.nightThemeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.night.next(value);
        if (value) {
          window.localStorage.setItem('theme', 'dark');
        } else {
          window.localStorage.removeItem('theme');
        }
      });
    }
  }

  exit() {
    sessionStorage.clear();
    window.location.href = '/';
  }

  openAccountSettings(user: any = null) {
    this.sidepageService
      .open<boolean>(new PolymorpheusComponent(SettingsComponent, this.injector), {
        size: 'l',
        headingTranslate: 'Settings',
        data: { user, isVisibleAdditionalParams: this.isVisibleAdditionalParams },
      } as any)
      .subscribe();
  }
}
