import { createAction, props } from '@ngrx/store';

export const getObjects = createAction('[Objects] Get');
export const storeObjects = createAction('[Objects] Store', props<{ list: any }>());
export const updateLastMessageForObject = createAction(
  '[Objects] Update last message',
  props<any>(),
);
export const updateObjectById = createAction(
  '[Objects] Update object by id',
  props<{ updatedObject: any }>(),
);
export const addObject = createAction('[Objects] Add new object', props<any>());
