import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { PaginatorService } from '@common/services/paginator.service';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.less'],
  providers: [TuiDestroyService],
})
export class TablePaginatorComponent implements OnInit {
  @Input() paginatorService: PaginatorService = new PaginatorService();

  pageSizeControl: FormControl = new FormControl(50);

  pageSize = [10, 50, 100, 1000];

  pageIndex: number = 1;

  length = 0;

  textForPaginator: string = this.getTextForPaginator();

  isDisabledNext: boolean = false;

  constructor(
    private destroy$: TuiDestroyService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.paginatorService.lengthList$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.length = val;
      if (!this.paginatorService.isSearching$.value) {
        this.pageIndex = 1;
      }
      this.updatePaginatorText();
    });

    this.paginatorService.pageIndex$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.pageIndex = val;
      this.updatePaginatorText();
    });

    this.pageSizeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.pageIndex = 1;
      this.paginatorService.pageSize$.next(val);
      this.updatePaginatorText();
    });
  }

  getTextForPaginator() {
    const pageSize = this.pageSizeControl.value;
    const fromCount = !this.length ? 0 : (this.pageIndex - 1) * pageSize + 1;
    let toCount = pageSize * this.pageIndex;

    if (toCount >= this.length) {
      toCount = this.length;
      this.isDisabledNext = true;
    } else {
      this.isDisabledNext = false;
    }
    return `${fromCount} - ${toCount} ${this.translate.instant('of')} ${this.length}`;
  }

  updatePaginatorText() {
    this.textForPaginator = this.getTextForPaginator();
  }

  clickNextCounter() {
    this.pageIndex = this.pageIndex + 1;
    this.paginatorService.pageIndex$.next(this.pageIndex);
  }

  clickPrevCounter() {
    this.pageIndex = this.pageIndex - 1;
    this.paginatorService.pageIndex$.next(this.pageIndex);
  }

  clickLastCounter() {
    this.pageIndex = Math.ceil(this.length / this.pageSizeControl.value);
    this.paginatorService.pageIndex$.next(this.pageIndex);
  }

  clickFirstCounter() {
    this.pageIndex = 1;
    this.paginatorService.pageIndex$.next(1);
  }
}
