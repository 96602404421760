import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
export class SearchComponent {
  @Output() searchEvent = new EventEmitter<string | null>();
  @Input() isLoading = false;

  public searchControl = new FormControl('');

  constructor(translate: TranslateService) {
    this.searchControl.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.searchEvent.emit(value);
    });
  }
}
